import {
  Portal,
  Flex,
  Text,
  useColorModeValue,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  Stack,
  Grid,
  Box,
  StatLabel,
  SimpleGrid,
  Stat,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BarChart from "components/Charts/BarChart";
import React, { useState, useEffect, useRef } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { AiOutlinePlus } from "react-icons/ai";
import { DownloadTableExcel } from "react-export-table-to-excel";

import MainPanel from "components/Layout/MainPanel";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.nescon.medicina.ufmg.br/",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

import {
  CNES_REPORT,
  CNES_REPORT2,
  CNES_REPORT3,
  GET_CBO_FAMILY_COLLECTION,
  GET_CBO_OCCUPACION_COLLECTION,
  GET_UFS,
  GET_REGIOES_SAUDE,
  GET_MUNICIPIOS_BY_CODE,
  GET_PROFESSIONS,
  GET_CBO_OCCUPACION_BY_CODE_INDEX_COLLECTION,
} from "queries";

const UFItems = [
  { name: "Acre", sigla: "AC", value: 12 },
  { name: "Alagoas", sigla: "AL", value: 27 },
  { name: "Amapá", sigla: "AP", value: 16 },
  { name: "Amazonas", sigla: "AM", value: 13 },
  { name: "Bahia", sigla: "BA", value: 29 },
  { name: "Ceará", sigla: "CE", value: 23 },
  { name: "Distrito Federal", sigla: "DF", value: 53 },
  { name: "Espírito Santo", sigla: "ES", value: 32 },
  { name: "Goiás", sigla: "GO", value: 52 },
  { name: "Maranhão", sigla: "MA", value: 21 },
  { name: "Mato Grosso", sigla: "MT", value: 51 },
  { name: "Mato Grosso do Sul", sigla: "MS", value: 50 },
  { name: "Minas Gerais", sigla: "MG", value: 31 },
  { name: "Pará", sigla: "PA", value: 15 },
  { name: "Paraíba", sigla: "PB", value: 25 },
  { name: "Paraná", sigla: "PR", value: 41 },
  { name: "Pernambuco", sigla: "PE", value: 26 },
  { name: "Piauí", sigla: "PI", value: 22 },
  { name: "Rio de Janeiro", sigla: "RJ", value: 33 },
  { name: "Rio Grande do Norte", sigla: "RN", value: 24 },
  { name: "Rio Grande do Sul", sigla: "RS", value: 43 },
  { name: "Rondônia", sigla: "RO", value: 11 },
  { name: "Roraima", sigla: "RR", value: 14 },
  { name: "Santa Catarina", sigla: "SC", value: 42 },
  { name: "São Paulo", sigla: "SP", value: 35 },
  { name: "Sergipe", sigla: "SE", value: 28 },
  { name: "Tocantins", sigla: "TO", value: 17 },
];

function DataTables() {
  const mainPanel = React.createRef();
  const tableRef = useRef(null);

  // const [sidebarWidth, setSidebarWidth] = useState(365);
  const [sidebarWidth, setSidebarWidth] = useState(0);

  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");

  // States
  const [isLoadigin, setIsLoading] = useState(false);
  const [cnesReport, setCnesReport] = useState(false);
  const [cboFamilyCollection, setCboFamilyCollection] = useState([]);
  const [allCboOccupacionCollection, setAllCboOccupacionCollection] = useState(
    []
  );
  const [reportColumns, setReportColumns] = useState([]);
  const [cbosFamily, setCbosFamily] = useState([]);
  const [cbosOccupation, setCbosOccupation] = useState([]);
  const [cboOccupationCollection, setCboOccupationCollection] = useState([]);
  const [cboOccupation, setCboOccupation] = useState(null);
  const [naturezaAgregada, setNaturezaAgregada] = useState(null);
  const [vinculoAgregado, setVinculoAgregado] = useState(null);
  const [UFs, setUFs] = useState([]);
  const [UF, setUF] = useState(null);
  const [specialties, setSpecialties] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState(false);
  const [showSelectedSpecialty, setShowSelectedSpecialty] = useState(false);
  const [selectedSpecialty, setSelectedSpecialty] = useState(false);
  const [showFormNewFilter, setShowFormNewFilter] = useState(false);
  const [typeNewFilter, setTypeNewFilter] = useState(null);
  const [matrizFilters, setMatrizFilters] = useState([]);
  const [filter, setFilter] = useState([]);
  const [column, setColumn] = useState("");
  const [reportType, setReportType] = useState("geral");
  const [indicador, setIndicador] = useState("0");

  const [
    selectedTipoVinculacaoComEstabelecimento,
    setTipoVinculacaoComEstabelecimento,
  ] = useState("total");

  const [professions, setProfessions] = useState([]);

  const [ufs, setUfs] = useState([]);
  const [regioesSaude, setRegioesSaude] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  const [yearSelected, setYearSelected] = useState("2024");
  const [ufSelected, setUfSelected] = useState("total");
  const [regioesSaudeSelected, setRegioesSaudeSelected] = useState("total");
  const [municipioSelected, setMunicipioSelected] = useState("total");

  const [isSusSelected, setIsSusSelected] = useState("total");

  const [professionSelected, setProfessionSelected] = useState(null);
  const [occupationSelected, setOccupationSelected] = useState(null);
  const [naturezaJuridicaSelected, setNaturezaJuridicaSelected] = useState(
    null
  );

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }

  const getReportDefault = async (v, filtersParam = []) => {
    setCbosOccupation([]);
    setCbosFamily([]);
    setUFs([]);
    setReportColumns([]);

    setReportType(v);
    if (
      v === "family" ||
      v === "occupation" ||
      v === "cod_uf" ||
      v === "year"
    ) {
      openModal();
    } else if (v === "geral") {
      if (filtersParam.length === 0) {
        filtersParam = matrizFilters;
      }

      let filters = [];
      filtersParam.map((matrizFilterItem, i) => {
        if (matrizFilterItem.type === "cod_uf") {
          filters.push({
            cod_uf: matrizFilterItem.uf,
          });
        } else if (matrizFilterItem.type === "familly") {
          filters.push({
            cbo_family: matrizFilterItem.cboFamily,
            cbo: matrizFilterItem.cboOccupation,
          });
        }
      });

      getReport(v, [], filters);
    }
  };

  const getReport = async (type, columnsParam, filtersParam) => {
    setIsLoading(true);
    var cnesReportResult = (
      await clientApi.query({
        variables: { type: type, columns: columnsParam, filter: filtersParam },
        query: CNES_REPORT2,
        fetchPolicy: "no-cache",
      })
    ).data.cnesReport2;
    setIsLoading(false);
    setCnesReport(cnesReportResult);
  };

  const getReportFilter = async (
    type,
    columnsParam,
    filtersParam,

    year,
    profession_code,
    natureza_juridica,
    occupation_code,

    uf_code,
    regiaosaude_code,
    municipio_code,
    isSus,
    tipoVinculacaoComEstabelecimento
  ) => {
    setIsLoading(true);
    var cnesReportResult = (
      await clientApi.query({
        variables: {
          type: type,
          columns: columnsParam,
          filter: filtersParam,

          year: year,

          profession_code: profession_code,
          natureza_juridica: natureza_juridica,
          occupation_code: occupation_code,

          uf_code: uf_code,
          regiaosaude_code: regiaosaude_code,
          municipio_code: municipio_code,
          isSus: isSus,

          tipoVinculacaoComEstabelecimento: tipoVinculacaoComEstabelecimento,
        },
        query: CNES_REPORT3,
        fetchPolicy: "no-cache",
      })
    ).data.cnesReport3;
    setIsLoading(false);
    setCnesReport(cnesReportResult);
  };

  const changeProfession = async (profession_code) => {
    setProfessionSelected(profession_code);
    setOccupationSelected(null);

    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      profession_code,
      naturezaJuridicaSelected,
      null,
      ufSelected,
      regioesSaudeSelected,
      municipioSelected,
      isSusSelected,
      selectedTipoVinculacaoComEstabelecimento
    );

    // Get occupation list
    var cbo_occupation_by_code_index = (
      await clientApi.query({
        variables: {
          code_index: profession_code,
        },
        query: GET_CBO_OCCUPACION_BY_CODE_INDEX_COLLECTION,
        fetchPolicy: "no-cache",
      })
    ).data.getCboOccupationByIndexCollection.cbo;
    setCboOccupationCollection(cbo_occupation_by_code_index);
  };

  const changeOccupation = async (occupation_code) => {
    setOccupationSelected(occupation_code);
    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      professionSelected,
      naturezaJuridicaSelected,
      occupation_code,
      ufSelected,
      regioesSaudeSelected,
      municipioSelected,
      isSusSelected,
      selectedTipoVinculacaoComEstabelecimento
    );
  };

  const changeNaturezaJuridica = (natureza_juridica) => {
    setNaturezaJuridicaSelected(natureza_juridica);
    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      professionSelected,
      natureza_juridica,
      occupationSelected,
      ufSelected,
      regioesSaudeSelected,
      municipioSelected,
      isSusSelected,
      selectedTipoVinculacaoComEstabelecimento
    );
  };

  const changeUf = async (v) => {
    setUfSelected(v);
    var uf_code = v;

    if (v == "total") {
      setRegioesSaude([]);
      setMunicipios([]);

      setRegioesSaudeSelected("total");
      setMunicipioSelected("total");
    } else {
      var getRegioesDeSuadeStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_REGIOES_SAUDE,
        })
      ).data.getRegioesSaude;
      setRegioesSaude(getRegioesDeSuadeStart);

      var getMunicipiosStart = (
        await clientApi.query({
          variables: {
            uf: v,
          },
          query: GET_MUNICIPIOS_BY_CODE,
        })
      ).data.getMunicipios.municipios;
      setMunicipios(getMunicipiosStart);
    }

    // Filtro
    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      professionSelected,
      naturezaJuridicaSelected,
      occupationSelected,
      uf_code,
      null,
      null,
      isSusSelected,
      selectedTipoVinculacaoComEstabelecimento
    );
  };

  const changeMunicipio = async (v) => {
    setRegioesSaudeSelected("total");
    setMunicipioSelected(v);

    // Filtro
    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      professionSelected,
      naturezaJuridicaSelected,
      occupationSelected,
      ufSelected,
      null,
      v,
      isSusSelected,
      selectedTipoVinculacaoComEstabelecimento
    );
  };

  const changeRegiaoSaude = async (v) => {
    setMunicipioSelected("total");
    setRegioesSaudeSelected(v);

    // Filtro
    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      professionSelected,
      naturezaJuridicaSelected,
      occupationSelected,
      ufSelected,
      v,
      null,
      isSusSelected,
      selectedTipoVinculacaoComEstabelecimento
    );
  };

  const changeIsSus = async (v) => {
    setIsSusSelected(v);

    // Filtro
    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      professionSelected,
      naturezaJuridicaSelected,
      occupationSelected,
      ufSelected,
      regioesSaudeSelected,
      municipioSelected,
      v,
      selectedTipoVinculacaoComEstabelecimento
    );
  };

  const changeTipoVinculacaoComEstabelecimento = async (v) => {
    setIsSusSelected(v);

    console.log("changeTipoVinculacaoComEstabelecimento");

    // Filtro
    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      professionSelected,
      naturezaJuridicaSelected,
      occupationSelected,
      ufSelected,
      regioesSaudeSelected,
      municipioSelected,
      isSusSelected,
      v
    );
  };

  const changeYear = async (v) => {
    setYearSelected(v);

    // Filtro
    getReportFilter(
      "geral",
      [],
      [],
      v,
      professionSelected,
      naturezaJuridicaSelected,
      occupationSelected,
      ufSelected,
      regioesSaudeSelected,
      municipioSelected,
      isSusSelected,
      selectedTipoVinculacaoComEstabelecimento
    );
  };

  useEffect(async () => {
    //#285d7d
    let r = document.querySelector(":root");
    r.style.setProperty("--chakra-colors-blue-500", "#285d7d");

    // Get Professions
    let getProfessions = (
      await clientApi.query({
        query: GET_PROFESSIONS,
        // fetchPolicy: 'no-cache'
      })
    ).data.getProfessions.professions;
    setProfessions(getProfessions);

    // GET UFS
    var getUfsStart = (
      await clientApi.query({
        query: GET_UFS,
      })
    ).data.getUfs;
    setUfs(getUfsStart);

    // let cboFamilyCollectionResult = (
    //   await clientApi.query({
    //     query: GET_CBO_FAMILY_COLLECTION,
    //     // fetchPolicy: 'no-cache'
    //   })
    // ).data.getCboFamlilyCollection.cboFamily;
    // setCboFamilyCollection(cboFamilyCollectionResult);

    // let allCboOccupacionCollectionResult = (
    //   await clientApi.query({
    //     query: GET_CBO_OCCUPACION_COLLECTION,
    //     // fetchPolicy: 'no-cache'
    //   })
    // ).data.getCboOccupationCollection.cbo;
    // setAllCboOccupacionCollection(allCboOccupacionCollectionResult);

    // Define o relatório incial
    //getReportDefault("geral");
    getReportFilter(
      "geral",
      [],
      [],
      yearSelected,
      professionSelected,
      naturezaJuridicaSelected,
      null,
      ufSelected,
      regioesSaudeSelected,
      municipioSelected,
      isSusSelected,
      selectedTipoVinculacaoComEstabelecimento
    );
  }, []);

  const changeCbosFamily = async (e) => {
    let isChecked = e.target.checked;
    let value = { cbo_family: e.target.value };
    let cbosFamilyClone = structuredClone(cbosFamily);
    if (isChecked && !cbosFamilyClone.includes(value)) {
      cbosFamilyClone.push(value);
    } else if (!isChecked && cbosFamilyClone.includes(value)) {
      cbosFamilyClone.splice(cbosFamilyClone.indexOf(value), 1);
    }
    setCbosFamily(cbosFamilyClone);
    setReportColumns(cbosFamilyClone);
  };

  const changeCbosOccupation = async (e) => {
    let isChecked = e.target.checked;
    let value = { cod_cbo: e.target.value };
    let cbosOccupationClone = structuredClone(cbosOccupation);
    if (isChecked && !cbosOccupationClone.includes(value)) {
      cbosOccupationClone.push(value);
    } else if (!isChecked && cbosOccupationClone.includes(value)) {
      cbosOccupationClone.splice(cbosOccupationClone.indexOf(value), 1);
    }
    setCbosOccupation(cbosOccupationClone);
    setReportColumns(cbosOccupationClone);
  };

  const changeUfs = async (e) => {
    let isChecked = e.target.checked;
    let value = { cod_uf: e.target.value };
    let UFsClone = structuredClone(UFs);
    if (isChecked && !UFsClone.includes(value)) {
      UFsClone.push(value);
    } else if (!isChecked && UFsClone.includes(value)) {
      UFsClone.splice(UFsClone.indexOf(value), 1);
    }
    setUFs(UFsClone);
    setReportColumns(UFsClone);
  };

  const changeYears = async (e) => {
    let isChecked = e.target.checked;
    let value = { year: e.target.value };
    let UFsClone = structuredClone(UFs);
    if (isChecked && !UFsClone.includes(value)) {
      UFsClone.push(value);
    } else if (!isChecked && UFsClone.includes(value)) {
      UFsClone.splice(UFsClone.indexOf(value), 1);
    }
    setUFs(UFsClone);
    setReportColumns(UFsClone);
  };

  // Para filtrar quais colunas devem aparecer
  var showColumns = [];
  showColumns["family"] = [];
  cbosFamily.map((item) => {
    showColumns["family"].push(item.cbo_family);
  });
  console.log("showColumns", showColumns);

  return (
    <React.Fragment>
      <MainPanel
        ref={mainPanel}
        w={{
          base: "100%",
          xl: `calc(100% - ${sidebarWidth}px)`,
        }}
      >
        <Grid>
          <Flex direction="column">
            <Flex
              direction={{ sm: "column", md: "row" }}
              justify="space-between"
              align="center"
              w="100%"
              mb="24px"
            ></Flex>

            <Card p="10px">
              <Card p="10px" className="margin-bottom-10">
                <CardHeader p="0px 5px 10px" mb="12px">
                  <Card>
                    <Flex direction="column">
                      <SimpleGrid columns={{ sm: 1, xl: 1 }}>
                        <Stat>
                          <StatLabel
                            fontSize="24"
                            color="#285D7D"
                            fontWeight="bold"
                            // textTransform="uppercase"
                            textAlign="center"
                          >
                            Matriz de Tipos de Serviços - CNES
                          </StatLabel>
                        </Stat>
                      </SimpleGrid>
                    </Flex>
                  </Card>
                  <br />
                  <Text
                    className="p"
                    color="#000"
                    fontSize="md"
                    fontWeight="regular"
                  >
                    Os arranjos assistenciais da saúde são formas de organização
                    dos serviços e das práticas de saúde que envolvem diferentes
                    níveis de complexidade, diferentes modalidades de atenção e
                    diferentes atores sociais. A matriz dos tipos de serviços
                    tem como objetivo dimensionar a composição da estrutura
                    assistencial e da força de trabalho alocada nos diferentes
                    tipos de serviços, a partir dos dados do Cadastro Nacional
                    de Estabelecimentos de Saúde (CNES), considerando a
                    classificação dos tipos de estabelecimentos da Portaria nº
                    2.022, de 7 de agosto de 2017, do Ministério da Saúde. Nela
                    estão presentes 25 tipos de estabelecimentos, agrupados em
                    quatro grupos de atividades: (i) Assistência à Saúde; (ii)
                    Vigilância em Saúde; (iii) Gestão da Saúde e; (iv) Outras
                    Atividades Relacionadas à Saúde Humana.
                  </Text>
                  <Text
                    className="p"
                    color="#000"
                    fontSize="md"
                    fontWeight="regular"
                  >
                    Navegue pela matriz para obter informações sobre os
                    diferentes indicadores segundo os tipos de serviços de saúde
                    e localidade. Passe o mouse em cima de cada indicador para
                    obter explicações.
                  </Text>
                </CardHeader>
                <CardBody px="5px">
                  <Card display="flex" justify="left" align="left" minH="0px">
                    <strong>Filtrar</strong>
                    <Flex direction="column" justify="space-between" h="100%">
                      <Grid
                        templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                        gap="20px"
                      >
                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <strong>Por Vínculos SUS / NÃO SUS:</strong>
                          <Flex align="center" mb="20px">
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="- Selecione -"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  changeIsSus(i.target.value);
                                }}
                                defaultValue={"total"}
                              >
                                <option value="total">
                                  Todos os vínculos SUS / NÃO SUS
                                </option>
                                <option value="1">Apenas SUS</option>
                                <option value="0">Apenas NÃO SUS</option>
                              </Select>
                            </FormControl>
                          </Flex>
                        </Card>
                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <strong>
                            Por Natureza Jurídica do Estabelecimento:
                          </strong>
                          <Flex align="center" mb="20px">
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="- Selecione -"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  changeNaturezaJuridica(i.target.value);
                                }}
                                defaultValue={"total"}
                              >
                                <option value="total">
                                  Todos os estabelecimentos de saúde
                                </option>
                                <option value="publico_municipal">
                                  Público Municipal
                                </option>
                                <option value="publico_estadual">
                                  Público Estadual
                                </option>
                                <option value="publico_federal">
                                  Público Federal
                                </option>
                                <option value="publico_outros">
                                  Público Outros
                                </option>
                                <option value="publico">Público Total</option>
                                <option value="privado">
                                  Privado Lucrativo
                                </option>
                                <option value="privado_nao_lucrativo">
                                  Privado Não Lucrativo
                                </option>
                              </Select>
                            </FormControl>
                          </Flex>
                        </Card>

                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <strong>
                            Tipo de Vinculação com o Estabelecimento:
                          </strong>
                          <FormControl>
                            <Select
                              variant="main"
                              placeholder="- Selecione -"
                              color="gray.400"
                              fontSize={16}
                              onChange={(i) => {
                                setTipoVinculacaoComEstabelecimento(
                                  i.target.value
                                );
                                changeTipoVinculacaoComEstabelecimento(
                                  i.target.value
                                );
                              }}
                              defaultValue={"total"}
                            >
                              <option value="total">TOTAL</option>
                              <option value="1">
                                Contratação direta com vínculo empregatício
                              </option>
                              <option value="2">
                                Contratação direta com vínculo autônomo
                              </option>
                              <option value="4">
                                Residência, estágio ou bolsa
                              </option>
                              <option value="3">
                                Contratação intermediada por empresa
                                ente/entidade
                              </option>
                              <option value="5">Outra</option>
                            </Select>
                          </FormControl>
                        </Card>
                      </Grid>

                      <Grid
                        templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                        gap="20px"
                      >
                        {professions.length > 0 && (
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <strong>Por profissão/ocupação de saúde:</strong>
                            <Flex align="center" mb="20px">
                              <FormControl>
                                <Select
                                  // textTransform="uppercase"
                                  variant="main"
                                  placeholder="- Selecione -"
                                  color="gray.400"
                                  fontSize={16}
                                  onChange={(i) => {
                                    changeProfession(i.target.value);
                                  }}
                                  defaultValue={"total"}
                                >
                                  {professions.map((profession) => (
                                    <option value={profession.code}>
                                      {profession.title}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </Flex>
                          </Card>
                        )}

                        {cboOccupationCollection.length > 0 && (
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <strong>
                              Por especialidade/ocupação de saúde:
                            </strong>
                            <Flex align="center" mb="20px">
                              <FormControl>
                                <Select
                                  // textTransform="uppercase"
                                  variant="main"
                                  placeholder="- Selecione -"
                                  color="gray.400"
                                  fontSize={16}
                                  onChange={(i) => {
                                    changeOccupation(i.target.value);
                                  }}
                                  defaultValue={"total"}
                                >
                                  {cboOccupationCollection.map(
                                    (cboOccupation) => (
                                      <option value={cboOccupation.code_num}>
                                        {cboOccupation.occupation}
                                      </option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Flex>
                          </Card>
                        )}
                      </Grid>
                    </Flex>
                  </Card>

                  <Card display="flex" justify="left" align="left" minH="0px">
                    <strong>Filtrar por ano e localidade</strong>
                    <Flex direction="column" justify="space-between" h="100%">
                      <Grid
                        templateColumns={{ sm: "1fr", md: "repeat(5, 1fr)" }}
                        gap="20px"
                      >
                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <strong>Ano:</strong>
                          <Flex align="center" mb="20px">
                            <FormControl>
                              <Select
                                variant="main"
                                placeholder="- Selecione -"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  changeYear(i.target.value);
                                }}
                                defaultValue={"2024"}
                              >
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                              </Select>
                            </FormControl>
                          </Flex>
                        </Card>
                        <Card
                          display="flex"
                          justify="left"
                          align="left"
                          minH="0px"
                        >
                          <string>UF:</string>
                          <FormControl>
                            <Select
                              // textTransform="uppercase"
                              variant="main"
                              placeholder="- Selecione -"
                              color="gray.400"
                              fontSize={16}
                              onChange={(i) => {
                                changeUf(i.target.value);
                              }}
                              defaultValue={"total"}
                            >
                              <option value="total">TOTAL BRASIL</option>
                              {ufs.map((uf) => (
                                <option value={uf.cod}>{uf.title}</option>
                              ))}
                            </Select>
                          </FormControl>
                        </Card>
                        {/* {regioesSaude.length > 0 && (
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <string>Macrorregião de Saúde CIR:</string>
                            <FormControl>
                              <Select
                                textTransform="uppercase"
                                variant="main"
                                placeholder="- Selecione -"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  changeRegiaoSaude(i.target.value);
                                }}
                                value={regioesSaudeSelected}
                              >
                                <option value="total">TOTAL</option>
                                {regioesSaude.map((regioaoSaude) => (
                                  <option value={regioaoSaude.cod}>
                                    {regioaoSaude.title}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Card>
                        )} */}
                        {regioesSaude.length > 0 && (
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <string>Microrregião de Saúde CIR:</string>
                            <FormControl>
                              <Select
                                // textTransform="uppercase"
                                variant="main"
                                placeholder="- Selecione -"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  changeRegiaoSaude(i.target.value);
                                }}
                                value={regioesSaudeSelected}
                              >
                                <option value="total">TOTAL</option>
                                {regioesSaude.map((regioaoSaude) => (
                                  <option value={regioaoSaude.cod}>
                                    {regioaoSaude.title}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Card>
                        )}
                        {regioesSaude.length > 0 && (
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <string>Município:</string>
                            <FormControl>
                              <Select
                                // textTransform="uppercase"
                                variant="main"
                                placeholder="- Selecione -"
                                color="gray.400"
                                fontSize={16}
                                onChange={(i) => {
                                  changeMunicipio(i.target.value);
                                }}
                                value={municipioSelected}
                              >
                                <option value="total">TOTAL</option>
                                {municipios.map((municipio) => (
                                  <option value={municipio.cod_ibge7}>
                                    {municipio.nom_mun_acent}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Card>
                        )}
                      </Grid>
                    </Flex>
                  </Card>

                  {(professionSelected !== null ||
                    naturezaJuridicaSelected !== null ||
                    ufSelected !== "total") && (
                    <Card display="flex" justify="left" align="left" minH="0px">
                      <Flex direction="column" justify="space-between" h="100%">
                        <Grid
                          templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
                          gap="20px"
                        >
                          <Card
                            display="flex"
                            justify="left"
                            align="left"
                            minH="0px"
                          >
                            <div
                              className="btn-black btn-g"
                              onClick={() => {
                                location.reload();
                              }}
                            >
                              LIMPAR FILTROS
                            </div>
                          </Card>
                        </Grid>
                      </Flex>
                    </Card>
                  )}
                </CardBody>
              </Card>
            </Card>
          </Flex>
        </Grid>
        {false && (
          <>
            <Grid>
              <Flex direction="column">
                <Flex
                  direction={{ sm: "column", md: "row" }}
                  justify="space-between"
                  align="center"
                  w="100%"
                  mb="24px"
                ></Flex>
                <Card p="10px">
                  <Card p="10px" className="margin-bottom-10">
                    <CardHeader p="12px 5px" mb="12px">
                      <Flex>
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                          Filtros
                        </Text>
                        <FormControl>
                          <Button
                            variant="dark"
                            alignSelf="flex-end"
                            ml="10px"
                            w="130px"
                            h="35px"
                            onClick={() => setShowFormNewFilter(true)}
                          >
                            + ADICIONAR FILTRO
                          </Button>
                        </FormControl>
                      </Flex>
                    </CardHeader>
                    <CardBody>
                      {matrizFilters.map((matrizFilter, indexMatrizFilter) => (
                        <div className="matrizFilterItem">
                          {matrizFilter.type === "family" && (
                            <Flex align="center" mb="20px">
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  Família:
                                </FormLabel>
                                <Select
                                  defaultValue={matrizFilter.cboFamily}
                                  variant="main"
                                  placeholder="Família"
                                  color="gray.400"
                                  fontSize="xs"
                                  onChange={(i) => {
                                    // changeCboFamily(i.target.value);
                                  }}
                                >
                                  {cboFamilyCollection.map((cboFamilyItem) => (
                                    <option value={cboFamilyItem.code_family}>
                                      {cboFamilyItem.occupational_family}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  Ocupação:
                                </FormLabel>
                                <Select
                                  defaultValue={matrizFilter.cboOccupation}
                                  variant="main"
                                  placeholder="Ocupação"
                                  color="gray.400"
                                  fontSize="xs"
                                  onChange={(i) => {
                                    // changeCboOccupation(i.target.value);
                                  }}
                                >
                                  {matrizFilter.cboOccupationOptins.map(
                                    (cboOccupationItem) => (
                                      <option
                                        value={cboOccupationItem.code_num}
                                      >
                                        {cboOccupationItem.occupation}
                                      </option>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              <Button
                                className="btn-red"
                                variant="dark"
                                alignSelf="flex-end"
                                mt="24px"
                                w="130px"
                                h="35px"
                                onClick={() =>
                                  removeItemMatrizFilters(indexMatrizFilter)
                                }
                              >
                                Remover
                              </Button>
                            </Flex>
                          )}
                          {matrizFilter.type === "cod_uf" && (
                            <Flex align="center" mb="20px">
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  UF:
                                </FormLabel>
                                <Select
                                  defaultValue={matrizFilter.uf}
                                  variant="main"
                                  placeholder="UF"
                                  color="gray.400"
                                  fontSize="xs"
                                  onChange={(i) => {
                                    setUF(i.target.value);
                                  }}
                                >
                                  {UFItems.map((uf_item) => (
                                    <option value={uf_item.value}>
                                      {uf_item.name}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                              <Button
                                className="btn-red"
                                variant="dark"
                                alignSelf="flex-end"
                                mt="24px"
                                w="130px"
                                h="35px"
                                onClick={() =>
                                  removeItemMatrizFilters(indexMatrizFilter)
                                }
                              >
                                Remover
                              </Button>
                            </Flex>
                          )}
                        </div>
                      ))}

                      {showFormNewFilter && (
                        <div className="matrizFilterItem">
                          <Flex align="center" mb="20px">
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Campo:
                              </FormLabel>
                              <Select
                                variant="main"
                                placeholder="Campo"
                                color="gray.400"
                                fontSize="xs"
                                onChange={(i) => {
                                  setTypeNewFilter(i.target.value);
                                }}
                              >
                                {/* <option value="geral">Geral</option> */}
                                {reportType !== "family" &&
                                  reportType !== "occupation" && (
                                    <option value="family">
                                      Famílias Ocupacionais da Saúde
                                    </option>
                                  )}

                                {reportType !== "occupation" && (
                                  <option value="occupation">
                                    Ocupações da Saúde
                                  </option>
                                )}
                                {reportType !== "year" && (
                                  <option value="year">Ano</option>
                                )}

                                {reportType !== "cod_uf" && (
                                  <option value="cod_uf">UF</option>
                                )}
                                <option value="municipios">Municípios</option>
                                <option value="regiao">Região</option>
                                <option value="regiao-de-saude">
                                  Região de saúde
                                </option>
                                <option value="regiao-de-saude">
                                  Natureza Jurídica
                                </option>
                                <option value="regiao-de-saude">
                                  Tipo de vínculo
                                </option>
                              </Select>
                            </FormControl>
                            {typeNewFilter === "family" && (
                              <>
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    Selecione a Família:
                                  </FormLabel>
                                  <Select
                                    variant="main"
                                    placeholder="- Selecione a Família -"
                                    color="gray.400"
                                    fontSize="xs"
                                    onChange={(i) => {
                                      // changeCboFamily(i.target.value);
                                    }}
                                  >
                                    {cboFamilyCollection.map(
                                      (cboFamilyItem) => (
                                        <option
                                          value={cboFamilyItem.code_family}
                                        >
                                          {cboFamilyItem.occupational_family}
                                        </option>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                                {cboOccupationCollection.length > 0 && (
                                  <>
                                    <FormControl>
                                      <FormLabel
                                        fontWeight="semibold"
                                        fontSize="xs"
                                        mb="10px"
                                      >
                                        Selecione a Ocupação:
                                      </FormLabel>
                                      <Select
                                        variant="main"
                                        placeholder="- Selecione a Ocupação -"
                                        color="gray.400"
                                        fontSize="xs"
                                        onChange={(i) => {
                                          // changeCboOccupation(i.target.value);
                                        }}
                                      >
                                        {cboOccupationCollection.map(
                                          (cboOccupationItem) => (
                                            <option
                                              value={cboOccupationItem.code_num}
                                            >
                                              {cboOccupationItem.occupation}
                                            </option>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                    {cboOccupation !== null && (
                                      <FormControl>
                                        <Button
                                          variant="dark"
                                          alignSelf="flex-end"
                                          mt="24px"
                                          w="130px"
                                          h="35px"
                                          onClick={() => {
                                            // Adicinoa o filtro na lista de filtros
                                            let currentMatrizFilters = structuredClone(
                                              matrizFilters
                                            );
                                            currentMatrizFilters.push({
                                              type: "family",
                                              cboFamily: cboFamily,
                                              cboOccupation: cboOccupation,
                                              cboOccupationOptins: cboOccupationCollection,
                                            });
                                            setMatrizFilters(
                                              currentMatrizFilters
                                            );
                                            // Limpa a seleção dos filtros
                                            setTypeNewFilter(null);
                                            setCbosFamily([]);
                                            setCboOccupation(null);
                                            setCboOccupationCollection([]);

                                            setShowFormNewFilter(false);
                                          }}
                                        >
                                          Concluir
                                        </Button>
                                      </FormControl>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {typeNewFilter === "cod_uf" && (
                              <>
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    Selecione a UF:
                                  </FormLabel>
                                  <Select
                                    variant="main"
                                    placeholder="- Selecione a UF -"
                                    color="gray.400"
                                    fontSize="xs"
                                    onChange={(i) => {
                                      setUF(i.target.value);
                                    }}
                                  >
                                    {UFItems.map((uf_item) => (
                                      <option value={uf_item.value}>
                                        {uf_item.name}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                                {UF !== null && (
                                  <FormControl>
                                    <Button
                                      variant="dark"
                                      alignSelf="flex-end"
                                      mt="24px"
                                      w="130px"
                                      h="35px"
                                      onClick={() => {
                                        // Adicinoa o filtro na lista de filtros
                                        let newMatrizFilters = structuredClone(
                                          matrizFilters
                                        );
                                        newMatrizFilters.push({
                                          type: "cod_uf",
                                          uf: UF,
                                        });
                                        setMatrizFilters(newMatrizFilters);
                                        // Limpa a seleção dos filtros
                                        setTypeNewFilter(null);
                                        setUF(null);

                                        setShowFormNewFilter(false);

                                        getReportDefault(
                                          reportType,
                                          newMatrizFilters
                                        );
                                      }}
                                    >
                                      Concluir
                                    </Button>
                                  </FormControl>
                                )}
                              </>
                            )}
                            <FormControl>
                              <Button
                                className="btn-red"
                                variant="dark"
                                alignSelf="flex-end"
                                mt="24px"
                                w="130px"
                                h="35px"
                                onClick={() => setShowFormNewFilter(false)}
                              >
                                Remover
                              </Button>
                            </FormControl>
                          </Flex>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Card>
              </Flex>
            </Grid>
          </>
        )}
        <Grid>
          <Flex direction="column">
            <Flex
              direction={{ sm: "column", md: "row" }}
              justify="space-between"
              align="center"
              w="100%"
              mb="24px"
            ></Flex>
            <Card p="10px">
              <CardBody>
                {isLoadigin && (
                  <Text
                    fontSize={{ sm: "lg", lg: "xl" }}
                    color={textColor}
                    fontWeight="bold"
                    ms={{ sm: "8px", md: "0px" }}
                  >
                    {" "}
                    Carregando...
                  </Text>
                )}

                {cnesReport === false && !isLoadigin && (
                  <Text
                    fontSize={{ sm: "lg", lg: "xl" }}
                    color={textColor}
                    fontWeight="bold"
                    ms={{ sm: "8px", md: "0px" }}
                  >
                    Aguarde...
                  </Text>
                )}

                {!isLoadigin && (
                  <div>
                    {/* <DownloadTableExcel
                        filename="Matriz de Serviços"
                        sheet="matriz-de-servicos"
                        currentTableRef={tableRef.current}
                    >
                      <button className="btn-black"> Export excel </button>
                  </DownloadTableExcel> */}
                    <strong>Matriz Tipos de Serviços {yearSelected}</strong>
                    <table
                      className="tableborder table-report table-matriz"
                      ref={tableRef}
                    >
                      <tbody>
                        {cnesReport !== false &&
                          cnesReport.report.map((item, i) => (
                            <>
                              <tr key={i}>
                                <td className="tableborder_1 background-td">
                                  <table>
                                    {
                                      // ##### Columns
                                      i === 0 && (
                                        <tr>
                                          {/* {cnesReport.filters.map((item, ii) => ( */}
                                          <th className="table-report-td">
                                            GRUPO DE ATIVIDADES
                                          </th>
                                          {/* ))} */}
                                        </tr>
                                      )
                                    }
                                    <tr>
                                      <td className="table-report-td">
                                        {item.name}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                                <td className="border_left">
                                  <table>
                                    <tbody>
                                      {item.items.map((item_2, ii) => (
                                        <>
                                          <tr key={ii}>
                                            <td className="tableborder_2">
                                              <table>
                                                {
                                                  // ##### Columns
                                                  i === 0 && ii === 0 && (
                                                    <tr>
                                                      <th className="table-report-td">
                                                        TIPO DE ESTABELECIMENTO
                                                      </th>
                                                    </tr>
                                                  )
                                                }
                                                <tr>
                                                  <td className="table-report-td">
                                                    {item_2.name}
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                            <td className="tableborder_4 ">
                                              <table>
                                                <tbody>
                                                  {
                                                    // ##### Columns Geral
                                                    reportType === "geral" &&
                                                      i === 0 &&
                                                      ii === 0 && (
                                                        <tr>
                                                          {cnesReport.filters.map(
                                                            (item, i) => (
                                                              <th
                                                                className={
                                                                  "table-report-td " +
                                                                  (i % 2 === 0
                                                                    ? "background-td"
                                                                    : "")
                                                                }
                                                              >
                                                                {/* <p>
                                                                        {
                                                                          item.profession
                                                                        }
                                                                      </p> */}
                                                                <p
                                                                  title={
                                                                    item.profession
                                                                  }
                                                                >
                                                                  {
                                                                    item.specialty
                                                                  }
                                                                </p>
                                                              </th>
                                                            )
                                                          )}
                                                        </tr>
                                                      )
                                                  }
                                                  {
                                                    // ### Colunas atravéz do resultado
                                                    reportType !== "geral" &&
                                                      i === 0 &&
                                                      ii === 0 &&
                                                      cnesReport !== false &&
                                                      typeof cnesReport.report !==
                                                        "undefined" &&
                                                      typeof cnesReport
                                                        .report[0] !==
                                                        "undefined" && (
                                                        <tr>
                                                          {cnesReport.report[0].items[0].items.map(
                                                            (column, i) => (
                                                              <th
                                                                className={
                                                                  "table-report-td " +
                                                                  (i % 2 === 0
                                                                    ? "background-td"
                                                                    : "")
                                                                }
                                                              >
                                                                <p
                                                                  title={
                                                                    column.name
                                                                  }
                                                                >
                                                                  {column.name}
                                                                </p>
                                                              </th>
                                                            )
                                                          )}
                                                        </tr>
                                                      )
                                                  }
                                                  <tr>
                                                    {item_2.items.map(
                                                      (item_3, iiii) => (
                                                        <>
                                                          {
                                                            // Se for geral, faz um .map em todos indicadores
                                                            reportType ===
                                                              "geral" &&
                                                              item_3.items.map(
                                                                (
                                                                  item_4,
                                                                  iiiii
                                                                ) => (
                                                                  <td
                                                                    key={iiiii}
                                                                    className={
                                                                      "tableborder_total table-report table-report-td " +
                                                                      (iiiii %
                                                                        2 ===
                                                                      0
                                                                        ? "background-td"
                                                                        : "")
                                                                    }
                                                                  >
                                                                    {new Intl.NumberFormat(
                                                                      "pt-br"
                                                                    ).format(
                                                                      item_4.total
                                                                    )}
                                                                  </td>
                                                                )
                                                              )
                                                          }

                                                          {
                                                            // Se NÃO for geral, faz exibe apenas o indicador selecionado
                                                            reportType !==
                                                              "geral" && (
                                                              // typeof showColumns[reportType] !== "undefined" &&
                                                              // showColumns[reportType].indexOf(item_3.code) >= 0 &&
                                                              <td
                                                                key={iiii}
                                                                className={
                                                                  "tableborder_total table-report table-report-td " +
                                                                  (iiii % 2 ===
                                                                  0
                                                                    ? "background-td"
                                                                    : "")
                                                                }
                                                              >
                                                                {
                                                                  // console.log("item_3",indicador, item_3.items)
                                                                }
                                                                {typeof item_3
                                                                  .items[
                                                                  indicador
                                                                ] !==
                                                                  "undefined" &&
                                                                  item_3.items[
                                                                    indicador
                                                                  ].total}
                                                              </td>
                                                            )
                                                          }
                                                        </>
                                                      )
                                                    )}
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </CardBody>

              <br />
              <hr />
              <br />

              <Text color="#000" fontSize="md" fontWeight="regular">
                Fonte: EPSM-ObservaRH/NESCON/FM/UFMG a partir dos dados do
                Cadastro Nacional de Estabelecimentos de Saúde (CNES) do
                Ministério da Saúde (MS).
              </Text>
            </Card>
          </Flex>
        </Grid>
      </MainPanel>
    </React.Fragment>
  );
}

export default DataTables;
