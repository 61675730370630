import React, { useState, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Circle,
  Tooltip,
  GeoJSON,
  ScaleControl,
} from "react-leaflet";
import "./Map.css";
import { BR } from "geoJson";
import { Text, useColorModeValue } from "@chakra-ui/react";

// const getColor = (d) => {
//   return d > 1000 ? '#800026' :
//          d > 500  ? '#BD0026' :
//          d > 200  ? '#E31A1C' :
//          d > 100  ? '#FC4E2A' :
//          d > 50   ? '#FD8D3C' :
//          d > 20   ? '#FEB24C' :
//          d > 10   ? '#FED976' :
//                     '#FFEDA0';
// }

// const style = (feature) => {
//   return {
//       fillColor: getColor(feature.properties.indicator),
//       weight: 2,
//       opacity: 1,
//       color: 'white',
//       dashArray: '3',
//       fillOpacity: 0.7
//   };
// }

const center = [-14.5, -53.7652544];

const COLOR_0 = "#F06E45";
const COLOR_1 = "#C9A83E";
const COLOR_24 = "#A1A436";
const COLOR_75 = "#789E2D";
const COLOR_140 = "#509923";
const COLOR_222 = "#3eb80e";

function getColor(d) {
  return d > 0.8
    ? COLOR_140
    : d > 0.6
    ? COLOR_75
    : d > 0.4
    ? COLOR_24
    : d > 0.2
    ? COLOR_1
    : COLOR_0;
}

function style(feature) {
  return {
    fillColor: getColor(feature.properties.sinaisDeMercadoPercent.eti),
    weight: 1,
    opacity: 0.5,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.7,
  };
}

const MapPlaceholder = () => {
  return (
    <p>
      Brasil <noscript>You need to enable JavaScript to see this map.</noscript>
    </p>
  );
};

function Map(props) {
  const {
    profession,
    indicatorColorMap,
    ufDefault,
    municipioCodSelected,
    latLong,
    areaSize,
  } = props;
  const [dataBR, setDataBR] = useState(null);
  const [dataMapUf, setDataMapUf] = useState(null);
  const [selected, setSelected] = useState({});
  const [ufSelected, setUfSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [longLatMap, setLongLatMap] = useState(center);
  const [zoomMap, setZoomMap] = useState(4.4999999999999996);

  const textColor = useColorModeValue("gray.700", "white");

  function style(feature) {
    // console.log("municipioCodSelected", municipioCodSelected);
    return {
      fillColor: getColor(
        feature.properties.sinaisDeMercadoPercent[indicatorColorMap]
      ),
      weight:
        municipioCodSelected !== "" &&
        municipioCodSelected === feature.properties.id
          ? 3
          : 1,
      opacity: 0.5,
      color:
        municipioCodSelected !== "" &&
        municipioCodSelected === feature.properties.id
          ? "black"
          : "white",
      dashArray: "3",
      fillOpacity: 0.7,
    };
  }

  useEffect(async () => {
    setLoading(true);

    if (latLong !== "") {
      // setLongLatMap();
      let latLongSplit = latLong.split(" ");
      // console.log("ffff", latLongSplit);
      // console.log([parseFloat(latLongSplit[0]), parseFloat(latLongSplit[1])]);
      setLongLatMap([parseFloat(latLongSplit[0]), parseFloat(latLongSplit[1])]);
      // console.log("calc", 330954/areaSize);
      // console.log(11.5*(330954/areaSize))
      setZoomMap(11.5); // Mapa ajustado para o tamanho de belo horizonte, e faz um cálculo para ajustar os outros pelo tamanho da área
      // console.log("areaSize", areaSize);
    } else {
      setLongLatMap(center);
      setZoomMap(4.4999999999999996);
    }

    const response = await fetch(
      `https://apipainelfts.nescon.medicina.ufmg.br//geojson/BR_2023_${profession.code}.json`
      //  `https://apipainelfts.nescon.medicina.ufmg.br//geojson/BR_2023_${profession.code}.json`
    );
    let dataBrReturn = await response.json();
    setDataBR(dataBrReturn);

    if (ufDefault !== "") {
      setUfSelected(ufDefault);
      let newDataMap = await getContent(ufDefault);
      setDataMapUf(newDataMap);
    } else {
      if (ufSelected !== null) {
        let newDataMap = await getContent(ufSelected);
        setDataMapUf(newDataMap);
      }
    }

    setLoading(false);
  }, [profession.code, ufDefault, latLong]);

  const getContent = async (UF) => {
    const response = await fetch(
      `https://apipainelfts.nescon.medicina.ufmg.br//geojson/${UF}_2023_${profession.code}.json`
      //  `https://apipainelfts.nescon.medicina.ufmg.br//geojson/${UF}_2023_${profession.code}.json`
    );
    return await response.json();
  };

  async function zoomToFeature(e) {
    //e.target.getBounds();
    if (ufSelected === null) {
      setUfSelected(e.target.feature.properties.UF_05);
      let newDataMap = await getContent(e.target.feature.properties.UF_05);
      setDataMapUf(newDataMap);
    }
  }

  function highlightFeature(e) {
    var layer = e.target;
    const { name, NOME_UF, sinaisDeMercado } = e.target.feature.properties;
    setSelected({
      province:
        typeof name !== "undefined"
          ? name
          : typeof NOME_UF !== "undefined"
          ? NOME_UF
          : "",
      eti_format: sinaisDeMercado ? sinaisDeMercado.eti_format : "",
      razao_1000_hab_format: sinaisDeMercado
        ? sinaisDeMercado.razao_1000_hab_format
        : "",
      num_empregos_formais_format: sinaisDeMercado
        ? sinaisDeMercado.num_empregos_formais_format
        : "",
      salario_medio_por_hora_format: sinaisDeMercado
        ? sinaisDeMercado.salario_medio_por_hora_format
        : "",
      participacao_feminina: sinaisDeMercado
        ? sinaisDeMercado.participacao_feminina
        : "",
    });
    layer.setStyle({
      weight: 2,
      color: "#DF1995",
      dashArray: "",
      fillOpacity: 0.3,
    });
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }
  function resetHighlight(e) {
    setSelected({});
    //e.target.setStyle(style(e.target.feature));
    e.target.setStyle(style(e.target.feature));
  }
  function onEachFeature(feature, layer) {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: zoomToFeature,
    });
  }

  return (
    <div className="panel">
      <div className="panel__map">
        {ufSelected === null && !selected.province && (
          <div className="hover-info">Clique no estado</div>
        )}
        {ufSelected !== null && !selected.province && (
          <div className="hover-info">Coloque o mouse sobre o município</div>
        )}

        {selected.province && (
          <div className="info">
            <div className="uf_name">{selected.province}</div>
            <div>
              <strong>CNES 2023:</strong>
            </div>
            <div>N. profissionais em ETI: {selected.eti_format}</div>
            <div>Razão por 1.000 hab.: {selected.razao_1000_hab_format}</div>
            <div>
              <strong>RAIS 2022:</strong>
            </div>
            <div>
              N. de empregos formais: {selected.num_empregos_formais_format}
            </div>
            <div>
              Remuneração média por hora: R${" "}
              {selected.salario_medio_por_hora_format}
            </div>
            <div>Participação feminina: {selected.participacao_feminina}%</div>
          </div>
        )}
        {ufSelected !== null && (
          <div
            className="btn-back-map"
            onClick={() => {
              setUfSelected(null);
              setDataMapUf(null);
            }}
          >
            &#x2039; Voltar para visão do mapa do Brasil
          </div>
        )}

        {indicatorColorMap === "participacao_feminina" && (
          <div className="legend">
            <div style={{ "--color": COLOR_140 }}>80% - 100%</div>
            <div style={{ "--color": COLOR_75 }}>61% - 80%</div>
            <div style={{ "--color": COLOR_24 }}>41% - 60%</div>
            <div style={{ "--color": COLOR_1 }}>21% - 40%</div>
            <div style={{ "--color": COLOR_0 }}>0% - 20%</div>
          </div>
        )}
        {indicatorColorMap !== "participacao_feminina" &&
          !loading &&
          dataMapUf === null &&
          dataBR !== null && (
            <div className="legend">
              <div style={{ "--color": COLOR_140 }}>
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataBR.minimo[indicatorColorMap] +
                    (dataBR.maximo[indicatorColorMap] -
                      dataBR.minimo[indicatorColorMap]) *
                      0.8
                  ).toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  dataBR.maximo[indicatorColorMap].toFixed(2)
                )}
              </div>
              <div style={{ "--color": COLOR_75 }}>
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataBR.minimo[indicatorColorMap] +
                    (dataBR.maximo[indicatorColorMap] -
                      dataBR.minimo[indicatorColorMap]) *
                      0.6
                  ).toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataBR.minimo[indicatorColorMap] +
                    (dataBR.maximo[indicatorColorMap] -
                      dataBR.minimo[indicatorColorMap]) *
                      0.8
                  ).toFixed(2)
                )}
              </div>
              <div style={{ "--color": COLOR_24 }}>
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataBR.minimo[indicatorColorMap] +
                    (dataBR.maximo[indicatorColorMap] -
                      dataBR.minimo[indicatorColorMap]) *
                      0.4
                  ).toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataBR.minimo[indicatorColorMap] +
                    (dataBR.maximo[indicatorColorMap] -
                      dataBR.minimo[indicatorColorMap]) *
                      0.6
                  ).toFixed(2)
                )}
              </div>
              <div style={{ "--color": COLOR_1 }}>
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataBR.minimo[indicatorColorMap] +
                    (dataBR.maximo[indicatorColorMap] -
                      dataBR.minimo[indicatorColorMap]) *
                      0.2
                  ).toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataBR.minimo[indicatorColorMap] +
                    (dataBR.maximo[indicatorColorMap] -
                      dataBR.minimo[indicatorColorMap]) *
                      0.4
                  ).toFixed(2)
                )}
              </div>
              <div style={{ "--color": COLOR_0 }}>
                {new Intl.NumberFormat("pt-br").format(
                  dataBR.minimo[indicatorColorMap].toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataBR.minimo[indicatorColorMap] +
                    (dataBR.maximo[indicatorColorMap] -
                      dataBR.minimo[indicatorColorMap]) *
                      0.2
                  ).toFixed(2)
                )}
              </div>
            </div>
          )}

        {indicatorColorMap !== "participacao_feminina" &&
          !loading &&
          dataMapUf !== null && (
            <div className="legend">
              <div style={{ "--color": COLOR_140 }}>
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataMapUf.minimo[indicatorColorMap] +
                    (dataMapUf.maximo[indicatorColorMap] -
                      dataMapUf.minimo[indicatorColorMap]) *
                      0.8
                  ).toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  dataMapUf.maximo[indicatorColorMap].toFixed(2)
                )}
              </div>
              <div style={{ "--color": COLOR_75 }}>
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataMapUf.minimo[indicatorColorMap] +
                    (dataMapUf.maximo[indicatorColorMap] -
                      dataMapUf.minimo[indicatorColorMap]) *
                      0.6
                  ).toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataMapUf.minimo[indicatorColorMap] +
                    (dataMapUf.maximo[indicatorColorMap] -
                      dataMapUf.minimo[indicatorColorMap]) *
                      0.8
                  ).toFixed(2)
                )}
              </div>
              <div style={{ "--color": COLOR_24 }}>
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataMapUf.minimo[indicatorColorMap] +
                    (dataMapUf.maximo[indicatorColorMap] -
                      dataMapUf.minimo[indicatorColorMap]) *
                      0.4
                  ).toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataMapUf.minimo[indicatorColorMap] +
                    (dataMapUf.maximo[indicatorColorMap] -
                      dataMapUf.minimo[indicatorColorMap]) *
                      0.6
                  ).toFixed(2)
                )}
              </div>
              <div style={{ "--color": COLOR_1 }}>
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataMapUf.minimo[indicatorColorMap] +
                    (dataMapUf.maximo[indicatorColorMap] -
                      dataMapUf.minimo[indicatorColorMap]) *
                      0.2
                  ).toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataMapUf.minimo[indicatorColorMap] +
                    (dataMapUf.maximo[indicatorColorMap] -
                      dataMapUf.minimo[indicatorColorMap]) *
                      0.4
                  ).toFixed(2)
                )}
              </div>
              <div style={{ "--color": COLOR_0 }}>
                {new Intl.NumberFormat("pt-br").format(
                  dataMapUf.minimo[indicatorColorMap].toFixed(2)
                )}{" "}
                -{" "}
                {new Intl.NumberFormat("pt-br").format(
                  (
                    dataMapUf.minimo[indicatorColorMap] +
                    (dataMapUf.maximo[indicatorColorMap] -
                      dataMapUf.minimo[indicatorColorMap]) *
                      0.2
                  ).toFixed(2)
                )}
              </div>
            </div>
          )}
        {!loading && (
          <MapContainer
            center={longLatMap}
            zoom={zoomMap}
            scrollWheelZoom={true}
            placeholder={<MapPlaceholder />}
          >
            <TileLayer
              attribution="Dados CNES e RAIS"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {dataMapUf === null && dataBR !== null && (
              <GeoJSON
                data={dataBR}
                style={style}
                onEachFeature={onEachFeature}
              />
            )}
            {dataMapUf !== null && (
              <GeoJSON
                data={dataMapUf}
                style={style}
                onEachFeature={onEachFeature}
              />
            )}
          </MapContainer>
        )}
      </div>
    </div>
  );
}

export default Map;
