import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  FormControl,
  FormLabel,
  Select,
  StatLabel,
  SimpleGrid,
  Stat,
} from "@chakra-ui/react";
import bgWeather from "assets/img/BgMusicCard.png";
import bgWeatherDark from "assets/img/bgMusicCardDark.png";
import smartHome from "assets/img/smart-home.png";
import sunBehindCloud from "assets/img/sun-behind-cloud.png";
import Card from "components/Card/Card";
import BarChart from "components/Charts/BarChart";
import { HSeparator } from "components/Separator/Separator";
import VisxPieChart from "components/VisxPieChart/VisxPieChart";
import CircularSlider from "react-circular-slider-svg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiWater, BiWifi } from "react-icons/bi";
import { BsFillRecordCircleFill, BsThermometerHigh } from "react-icons/bs";
import { FaPlus, FaSnowflake } from "react-icons/fa";
import { IoBulbOutline, IoEllipsisVerticalSharp } from "react-icons/io5";
import {
  barChartDataDashboardPage,
  barChartOptionsDashboardPage,
} from "variables/charts";
import { rooms } from "variables/general";

import SinaisDeMercadoTab1 from "./components/SinaisDeMercado/Tab1";
import SinaisDeMercadoTab2Cnes from "./components/SinaisDeMercado/Tab2-cnes";
import SinaisDeMercadoTab2Rais from "./components/SinaisDeMercado/Tab2-rais";
import SinaisDeMercadoTab3 from "./components/SinaisDeMercado/Tab3";
import SinaisDeMercadoTab4 from "./components/SinaisDeMercado/Tab4";

import { useMutation, useQuery } from "@apollo/client";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { AiOutlinePlus } from "react-icons/ai";
import { GET_SPECIALTIES, GET_PROFESSIONS } from "queries";

const restLinkApi = new HttpLink({
  credentials: "include",
  // uri: "/api-account",
  uri: "https://apipainelfts.nescon.medicina.ufmg.br/",
});
const clientApi = new ApolloClient({
  link: restLinkApi,
  cache: new InMemoryCache(),
});

const DashboardPage = () => {
  const { colorMode } = useColorMode();
  const [distritc, setDistrict] = useState("");
  const [activeButton, setActiveButton] = useState({
    regulacao: false,
    sinaisDeMercadoCnes: true,
    sinaisDeMercadoRais: false,
    distribuicaoGeografica: false,
    fluxoDaFormacao: false,
  });

  const ufs = [
    { code: "11", name: "RO" },
    { code: "12", name: "AC" },
    { code: "13", name: "AM" },
    { code: "14", name: "RR" },
    { code: "15", name: "PA" },
    { code: "16", name: "AP" },
    { code: "17", name: "TO" },
    { code: "21", name: "MA" },
    { code: "22", name: "PI" },
    { code: "23", name: "CE" },
    { code: "24", name: "RN" },
    { code: "25", name: "PB" },
    { code: "26", name: "PE" },
    { code: "27", name: "AL" },
    { code: "28", name: "SE" },
    { code: "29", name: "BA" },
    { code: "31", name: "MG" },
    { code: "32", name: "ES" },
    { code: "33", name: "RJ" },
    { code: "35", name: "SP" },
    { code: "41", name: "PR" },
    { code: "42", name: "SC" },
    { code: "43", name: "RS" },
    { code: "50", name: "MS" },
    { code: "51", name: "MT" },
    { code: "52", name: "GO" },
    { code: "53", name: "DF" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  const textColor = useColorModeValue("gray.700", "white");
  const bgButtonGroup = useColorModeValue("gray.200", "navy.700");
  const bgActiveButton = useColorModeValue("#fff", "navy.800");
  const arcBackgroundColor = useColorModeValue("#EDF2F7", "#0B1437");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const iconColor = useColorModeValue("gray.400", "white");
  const bgIcon = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "navy.800"
  );

  const [selectedProfession, setSelectedProfession] = useState(false);
  const [profession, setProfession] = useState(null);
  const [professions, setProfessions] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState(false);
  const changeProfession = async (v, professionsDefault = null) => {
    setSelectedProfession(v);
    var professionsList =
      professionsDefault !== null ? professionsDefault : professions;
    professionsList.map((profession_item) => {
      if (v === profession_item.code) {
        console.log(profession_item);
        setProfession(profession_item);
      }
    });
  };

  useEffect(async () => {
    //#285d7d
    let r = document.querySelector(":root");
    r.style.setProperty("--chakra-colors-blue-500", "#285d7d");

    let getProfessions = (
      await clientApi.query({
        query: GET_PROFESSIONS,
        // fetchPolicy: 'no-cache'
      })
    ).data.getProfessions.professions;
    setProfessions(getProfessions);

    // Coloca o primeiro como padrão
    changeProfession("total", getProfessions);
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "125px", lg: "0px" }}>
      <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} gap="20px">
        <Card>
          <Flex direction="column">
            <SimpleGrid columns={{ sm: 1, xl: 1 }}>
              <Stat>
                <StatLabel
                  fontSize="24"
                  color="#285D7D"
                  fontWeight="bold"
                  textTransform="uppercase"
                  textAlign="center"
                >
                  Panorama por profissão e ocupação de saúde
                </StatLabel>
              </Stat>
            </SimpleGrid>
          </Flex>
        </Card>
        <Card>
          <Flex align="flex-end" mb="0px">
            <table className="table-filter">
              {false && selectedProfession === false && (
                <tr>
                  <td>
                    <Text color="#000" fontSize="3xl" fontWeight="bold">
                      Panorama por profissão e ocupação de saúde
                    </Text>
                    {/*                   
                        <Text color="#000" fontSize='md' fontWeight='regular'>As profissões e ocupações de saúde são categorias utilizadas para se referir a grupos de trabalhadores que desempenham funções, atribuições, ações ou procedimentos que requerem formação acadêmica e/ou prática específica na área da saúde.</Text>
                        <Text color="#000" fontSize='md' fontWeight='regular'>Podem ser profissões regulamentadas, com jurisdições legais exclusivas, autonomia e autorregulação/autogoverno, como médicos, cirurgiões-dentistas, enfermeiros e fisioterapeutas, etc.</Text>
                        <Text color="#000" fontSize='md' fontWeight='regular'>Podem ser ocupações não regulamentadas, mas que requerem formação específica em saúde, como ortoptistas, optometristas e quiropraxistas, etc.</Text>
                        <Text color="#000" fontSize='md' fontWeight='regular'>Podem ser, ainda, ocupações que não requerem formação específica em saúde, mas que requerem práticas relacionadas às atividades de saúde, como recepcionistas de consultório médico e condutores de ambulância, etc.</Text>
                        <Text color="#000" fontSize='md' fontWeight='regular'>No Brasil, existem cerca de 90 tipos diferentes de profissões e ocupações de saúde que podem ser identificadas através da Classificação Brasileira de Ocupações (CBO). </Text>
                        <Text color="#000" fontSize='md' fontWeight='regular'>Escolha abaixo uma delas para obter informações detalhadas.</Text>
                         */}
                  </td>
                </tr>
              )}
              <tr>
                <td width="100%">
                  <Text color="#000" fontSize="18px" fontWeight="bold">
                    Filtrar por profissão e ocupação de saúde:
                  </Text>
                  {professions.length === 0 && <div>Carregando...</div>}
                  {professions.length > 0 && (
                    <FormControl>
                      <Select
                        variant="main"
                        placeholder="- Selecione -"
                        color="gray.400"
                        fontSize={16}
                        onChange={(i) => {
                          changeProfession(i.target.value);
                        }}
                        defaultValue={"total"}
                      >
                        {professions.map((profession) => (
                          <option value={profession.code}>
                            {profession.title}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </td>
              </tr>
            </table>
          </Flex>
        </Card>

        {profession !== null && selectedProfession !== false && (
          <>
            <Card>
              <Flex
                direction={{ sm: "column", md: "row" }}
                justify="space-between"
                align={{ md: "center" }}
                mb="0px"
              >
                <Flex align="center">
                  <Flex
                    bg={bgButtonGroup}
                    borderRadius="10px"
                    p="6px"
                    me="10px"
                  >
                    {/* <Button
                  variant='no-effects'
                  w={{ sm: "fit-content", xl: "280px" }}
                  h='40px'
                  fontSize='xs'
                  boxShadow={
                    activeButton.regulacao
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeButton.regulacao ? bgActiveButton : "transparent"}
                  onClick={() =>
                    setActiveButton({
                      regulacao: true,
                      sinaisDeMercadoCnes: false,
                      sinaisDeMercadoRais: false,
                      distribuicaoGeografica: false,
                      fluxoDaFormacao: false,
                    })
                  }>
                  DESCRIÇÃO DA PROFISSÃO/OCUPAÇÃO
                </Button> */}
                    <Button
                      variant="no-effects"
                      w={{ sm: "fit-content", xl: "210px" }}
                      h="40px"
                      fontSize="xs"
                      boxShadow={
                        activeButton.sinaisDeMercadoCnes
                          ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                          : "none"
                      }
                      bg={
                        activeButton.sinaisDeMercadoCnes
                          ? bgActiveButton
                          : "transparent"
                      }
                      onClick={() =>
                        setActiveButton({
                          regulacao: false,
                          sinaisDeMercadoCnes: true,
                          sinaisDeMercadoRais: false,
                          distribuicaoGeografica: false,
                          fluxoDaFormacao: false,
                        })
                      }
                    >
                      OFERTA E DEMANDA
                    </Button>
                    <Button
                      variant="no-effects"
                      w={{ sm: "fit-content", xl: "250px" }}
                      h="40px"
                      fontSize="xs"
                      boxShadow={
                        activeButton.sinaisDeMercadoRais
                          ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                          : "none"
                      }
                      bg={
                        activeButton.sinaisDeMercadoRais
                          ? bgActiveButton
                          : "transparent"
                      }
                      onClick={() =>
                        setActiveButton({
                          regulacao: false,
                          sinaisDeMercadoCnes: false,
                          sinaisDeMercadoRais: true,
                          distribuicaoGeografica: false,
                          fluxoDaFormacao: false,
                        })
                      }
                    >
                      EMPREGOS E REMUNERAÇÃO
                    </Button>
                    <Button
                      variant="no-effects"
                      w={{ sm: "fit-content", xl: "185px" }}
                      h="40px"
                      fontSize="xs"
                      boxShadow={
                        activeButton.distribuicaoGeografica
                          ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                          : "none"
                      }
                      bg={
                        activeButton.distribuicaoGeografica
                          ? bgActiveButton
                          : "transparent"
                      }
                      onClick={() =>
                        setActiveButton({
                          regulacao: false,
                          sinaisDeMercadoCnes: false,
                          sinaisDeMercadoRais: false,
                          distribuicaoGeografica: true,
                          fluxoDaFormacao: false,
                        })
                      }
                    >
                      DISTRIBUIÇÃO GEOGRÁFICA
                    </Button>
                    {/* <Button
                  variant='no-effects'
                  w={{ sm: "fit-content", xl: "185px" }}
                  h='40px'
                  fontSize='xs'
                  boxShadow={
                    activeButton.fluxoDaFormacao
                      ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                      : "none"
                  }
                  bg={activeButton.fluxoDaFormacao ? bgActiveButton : "transparent"}
                  onClick={() =>
                    setActiveButton({
                      regulacao: false,
                      sinaisDeMercadoCnes: false,
                      sinaisDeMercadoRais: false,
                      distribuicaoGeografica: false,
                      fluxoDaFormacao: true,
                    })
                  }>
                  FLUXOS DA FORMAÇÃO
                </Button>  */}
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </>
        )}
      </Grid>

      {profession !== null &&
        selectedProfession !== false &&
        activeButton.regulacao && (
          <SinaisDeMercadoTab1 profession={profession} />
        )}
      {profession !== null &&
        selectedProfession !== false &&
        activeButton.sinaisDeMercadoCnes && (
          <SinaisDeMercadoTab2Cnes profession={profession} />
        )}
      {profession !== null &&
        selectedProfession !== false &&
        activeButton.sinaisDeMercadoRais && (
          <SinaisDeMercadoTab2Rais profession={profession} />
        )}
      {profession !== null &&
        selectedProfession !== false &&
        activeButton.distribuicaoGeografica && (
          <SinaisDeMercadoTab3 profession={profession} ufs={ufs} />
        )}
    </Flex>
  );
};

export default DashboardPage;
